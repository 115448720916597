import React from 'react';
import {Colors} from '@styles';
import LatestCategoriesLoader from '@util/LatestCategoriesLoader';
import Subnavigation from '@components/Subnavigation';
import StickySection from './StickySection';
import {useContainerSize} from '@components/layout';

export interface LatestSubNavSectionProps {
  filter?: boolean;
  inline?: boolean;
}

const LatestSubNavSection = ({
  inline,
  filter = true,
}: LatestSubNavSectionProps): JSX.Element => {
  const {wide} = useContainerSize();
  return (
    <StickySection
      stuck={inline ? false : undefined}
      theme={{
        bg: Colors.White,
        fg: Colors.Charcoal,
        maxPadding: '0',
        minPadding: '0',
        maxWidth: '100%',
      }}
    >
      <LatestCategoriesLoader>
        {latestCategories => (
          <Subnavigation
            categories={latestCategories}
            filter={filter}
            wide={wide}
          />
        )}
      </LatestCategoriesLoader>
    </StickySection>
  );
};

export default LatestSubNavSection;
