import React, {Fragment} from 'react';
import {graphql} from 'gatsby';
import Meta from '@components/Meta';
import CanonicalUrl from '@components/CanonicalUrl';
import {ResponsiveContainer, FontScale} from '@components/layout';
import {usePageContext} from '@components/Page';
import {
  LatestSection,
  CrosslinkSection,
  LatestSubNavSection,
} from '@components/sections/';
import RichTextCallout, {
  RichTextNode,
} from '@components/SectionCallout/RichTextCallout';
import SocialSharingWidget from '@components/SocialSharingWidget';
import {Headline, Paragraph} from '@components/typography';
import {StyledSection} from '@components/sections';
import {TextLink} from '@components/Link';
import Testimonial, {TestimonialProps} from '@components/Testimonial';
import InlineYoutubeVideo from '@components/Video/InlineYoutubeVideo';
import Image from '@components/Image';
import TwoImageWithCaption from '@components/Image/TwoImageWithCaption';
import {FluidObject} from 'gatsby-image';
import {styled, ThemeProvider, Colors, Fonts} from '@styles';
import {Category, Author, Tag} from '@components/LatestPostGrid/FeaturedPost';
import formatLatestPostDate from '@util/formatLatestPostDate';
import getDataFromBlockTypes from '@util/getDataFromBlockTypes';
import JsonLd from '@components/JsonLd';
import generateByline from '../util/generateByline';
import {getSrc} from 'gatsby-plugin-image'

interface LatestDetailHeroProps {
  category: Category;
  authors: Author[];
  tags: Tag[];
  publicationDate: string;
  title: string;
  searchDescription: string;
  url: string;
  excerpt: string;
}

const LatestHeroContentContainer = styled.div.withConfig({
  componentId: 'latestContentLatestHeroContentConatainer'
})`
  display: grid;
  grid-template-columns: 1fr;
  flex-direction: row;
  justify-items: flex-end;
  align-items: flex-start;
  padding: 5em 0 0;

  > div:first-child {
    /* force headline to align start */
    justify-self: flex-start;
  }
  .wide & {
    padding: 5em 0 2em;
    grid-template-columns: 3fr 1fr;
  }
`;

const StyledHeadline = styled(Headline).withConfig({
  componentId: 'latestContentHeadline'
})`
  font-size: ${48 / 16}em;

  .wide & {
    font-size: ${67 / 16}em;
  }
`;

const Eyebrow = styled(Paragraph).withConfig({
  componentId: 'latestContentEyebrow'
})`
  text-transform: uppercase;
  color: ${Colors.Blue};
  font-weight: bold;
`;

const ImageStyledSection = styled(StyledSection).withConfig({
  componentId: 'latestContentImageStyledSection'
})`
  max-width: 900px;
  padding: 0;
`;

const ImageBlockCopy = styled.span.withConfig({
  componentId: 'latestContentImageBlockCopy'
})`
  ${Fonts.ProximaNova};
  color: ${Colors.CharcoalLight};
  font-size: ${15 / 16}em;
  margin: ${12 / 25}em 1em;
  max-width: 1200px;
  display: block;

  .wide & {
    font-size: ${14 / 16}em;
    margin: ${12 / 25}em 2em;
  }

  .extraWide & {
    margin: ${12 / 25}em auto;
  }
`;

const RichTextStyledSection = styled(StyledSection).withConfig({
  componentId: 'latestContentRichTextStyledSection'
})`
  max-width: 1200px;

  > div {
    .wide & {
      padding: 0 6em;
    }
  }
`;

const VideoStyledSection = styled(StyledSection).withConfig({
  componentId: 'latestContentVideoStyledSection'
})`
  max-width: 1440px;
`;

const ImageGridStyledSection = styled(StyledSection).withConfig({
  componentId: 'latestContentImageGridStyledSection'
})`
  max-width: 1440px;
  padding: 0;
`;

const HeroStyledSection = styled(StyledSection).withConfig({
  componentId: 'latestContentHeroStyledSection'
})`
  max-width: 1200px;
`;

/**
 * All the enabled blocks for the latest content blog post
 * based on their blockType
 *
 */
const enabledRenderBlocks: {[key: string]: (block: any) => JSX.Element} = {
  copy_block: (block: {body: RichTextNode}): JSX.Element => {
    return (
      <RichTextStyledSection>
        <RichTextCallout body={block.body} />
      </RichTextStyledSection>
    );
  },
  image_block: (block: {
    attribution: string;
    caption: string;
    image: {childImageSharp: {fluid: FluidObject}};
  }): JSX.Element => {
    return (
      block.image && (
        <ImageStyledSection>
          <Image
            alt={block.attribution}
            childImageSharp={
              block.image.childImageSharp && block.image.childImageSharp
            }
            style={{height: '100%', width: '100%'}}
            imgStyle={{
              height: '100%',
              width: '100%',
              objectFit: 'contain',
            }}
            {...block.image}
          />
          <ImageBlockCopy>{block.caption}</ImageBlockCopy>
        </ImageStyledSection>
      )
    );
  },
  testimonial_block: (block: {
    testimonialBlock: TestimonialProps;
  }): JSX.Element => {
    return <Testimonial {...block.testimonialBlock} />;
  },
  video_block: (block: {
    youtube: string;
    image: {childImageSharp: {fluid: FluidObject}};
    video: {url: string};
    caption: string;
  }): JSX.Element => {
    const {youtube, image, video, caption} = block;
    return (
      <VideoStyledSection>
        <InlineYoutubeVideo
          videoId={youtube}
          placeholderType={image ? `image` : video ? 'video' : null}
          placeholderImage={image ? image.childImageSharp : null}
          placeholderVideo={video ? video.url : null}
        />
        <ImageBlockCopy>{caption}</ImageBlockCopy>
      </VideoStyledSection>
    );
  },
  imagegrid_block: (block: {
    imageBlocks: {
      attribution: string;
      caption: string;
      image: {
        childImageSharp: {fluid: FluidObject};
        height: number;
        width: number;
        focalPointX: number;
        focalPointY: number;
      };
    }[];
  }): JSX.Element => {
    const {imageBlocks} = block;
    return (
      <ImageGridStyledSection>
        {imageBlocks.length > 1 && (
          <TwoImageWithCaption
            imageOne={imageBlocks[0].image}
            imageOneAlt={imageBlocks[0].attribution}
            imageOneCaption={imageBlocks[0].caption}
            imageTwo={imageBlocks[1].image}
            imageTwoAlt={imageBlocks[1].attribution}
            imageTwoCaption={imageBlocks[1].caption}
          />
        )}
      </ImageGridStyledSection>
    );
  },
};

function renderBlocks(blocks: {blockType: string}[]): (JSX.Element | null)[] {
  return blocks.map((block: {blockType: string}): JSX.Element | null => {
    return enabledRenderBlocks[block.blockType]
      ? enabledRenderBlocks[block.blockType](block)
      : null;
  });
}

const Byline = styled(Paragraph).withConfig({
  componentId: 'latestContentByline'
})`
  margin-bottom: 0;
  & + & {
    margin-top: 0;
  }
`;

function LatestHeroContent({
  category,
  title,
  authors,
  tags,
  publicationDate,
  url,
}: LatestDetailHeroProps): JSX.Element {
  return (
    <ThemeProvider
      theme={{
        ctaBg: Colors.White,
        ctaFg: Colors.Blue,
        ctaHoverFg: Colors.Blue,
      }}
    >
      <LatestHeroContentContainer>
        <div>
          <Eyebrow>{category.name}</Eyebrow>
          <StyledHeadline level={1}>{title}</StyledHeadline>
          <Byline>
            {`Written by `}
            {authors && generateByline(authors)}
            &nbsp;
            {`on ${formatLatestPostDate(publicationDate)}`}
          </Byline>
          {tags && tags.length > 0 && (
            <Byline>
              {`Related content: `}
              {tags.map((tag, i) => (
                <TextLink key={tag.name} href={`/newsroom/?tags=${tag.name}`}>
                  {i === tags.length - 1 ? tag.name : tag.name + ', '}
                </TextLink>
              ))}
            </Byline>
          )}
        </div>
        <SocialSharingWidget
          fixedPositionMobile={true}
          showShareText={true}
          shareUrl={`https://2u.com${url}`}
          emailSubject={`Check out the latest from 2U`}
          emailBody={`Check out the latest from 2U #nobackrow: `}
          twitterTitle={`Check out the latest from 2U:`}
          twitterHashtags={['nobackrow']}
          facebookQuote={`Check out the latest from 2U`}
        />
      </LatestHeroContentContainer>
    </ThemeProvider>
  );
}

/**
 * TODO: type data.
 */
export default function LatestDetail({data}): JSX.Element {
  usePageContext({shouldInvertMenuColors: true});
  const {page} = data.cms;
  const {latestentrypage} = page;
  const {
    body,
    title,
    image,
    tags,
    category,
    authors,
    publicationDate,
    seoTitle,
    searchDescription,
    url,
    canonicalUrl,
  } = latestentrypage;

  const {crossLink} = getDataFromBlockTypes(body);
  const publishedDate = formatLatestPostDate(publicationDate);
  const heroImage = getSrc(image)
  return (
    <Fragment>
      <Meta title={title} seoTitle={seoTitle} description={searchDescription} />
      <CanonicalUrl
        canonicalUrl={canonicalUrl ? canonicalUrl : `https://2u.com${url}`}
      />
      <JsonLd
        schema={{
          '@context': 'https://schema.org',
          '@type': 'BlogPosting',
          headline: `${title}`,
          description: `${searchDescription}`,
          datePublished: `${publishedDate}`,
          dateModified: `${publishedDate}`,
          mainEntityOfPage: `${url}`,
          author: {
            '@type': 'Person',
            name: `${authors[0].name}`,
            image: `${authors[0].photo.url}`,
          },
        }}
        addPublisher={true}
        addLogo={false}
        images={[
          heroImage
            ? heroImage
            : null,
        ]}
      />
      <ThemeProvider
        theme={{
          minPadding: '2em',
          maxPadding: '2em',
          maxWidth: '100%',
        }}
      >
        <ResponsiveContainer as={FontScale}>
          <ThemeProvider theme={{fg: Colors.Charcoal, bg: Colors.White}}>
            <Fragment>
              <HeroStyledSection>
                <LatestHeroContent
                  category={category}
                  tags={tags}
                  title={title}
                  authors={authors}
                  publicationDate={publicationDate}
                  url={url}
                />
              </HeroStyledSection>
              <LatestSubNavSection inline />
            </Fragment>
          </ThemeProvider>
          <ThemeProvider
            theme={{
              fg: Colors.Charcoal,
              ctaBg: Colors.White,
              ctaFg: Colors.Blue,
              ctaHoverFg: Colors.Blue,
            }}
          >
            {renderBlocks(body)}
          </ThemeProvider>
          <LatestSection />
          {crossLink && <CrosslinkSection {...crossLink} />}
        </ResponsiveContainer>
      </ThemeProvider>
    </Fragment>
  );
}

export const query = graphql`
  fragment LatestTagFragment on CMS_Tag {
    name
    slug
  }
  fragment LatestCategoryFragment on CMS_Category {
    name
    slug
    header
    subheader
  }
  fragment LatestAuthorsFragment on CMS_Author {
    name
    slug
    jobTitle
    photo {
      url
    }
    bio
    social {
      url
    }
  }
  fragment LatestDetailFragment on CMS_LatestEntryPage {
    latestentrypage {
      title
      seoTitle
      searchDescription
      canonicalUrl
      publicationDate
      url
      image {
        ...WagtailImageSharpSource
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
      tags {
        ...LatestTagFragment
      }
      category {
        ...LatestCategoryFragment
      }
      authors {
        ...LatestAuthorsFragment
      }
      body {
        __typename
        ...headerBodyFragment
        ...crosslinkBlockFragment
        ...imageFragment
        ...testimonialSimpleFragment
        ...latestVideoFragment
        ...imageGridFragment
      }
    }
  }
  query LatestDetail($id: ID!) {
    cms {
      page(id: $id) {
        ...LatestDetailFragment
      }
    }
  }
`;

export const imageFragment = graphql`
  fragment imageFragment on CMS_ImageBlock {
    blockType
    attribution
    caption
    image {
      ...WagtailImageSharpSource
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 90
        )
      }
      height
      width
      focalPointX
      focalPointY
    }
  }
`;

export const testimonialSimpleFragment = graphql`
  fragment testimonialSimpleFragment on CMS_TestimonialSimpleBlock {
    blockType
    testimonialBlock {
      author
      photo {
        ...WagtailImageSharpSource
        url
        focalPointX
        focalPointY
        width
        height
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 90
          )
        }
      }
      body
    }
  }
`;

export const latestVideoFragment = graphql`
  fragment latestVideoFragment on CMS_VideoBlock {
    blockType
    video {
      url
    }
    image {
      ...WagtailImageSharpSource
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          quality: 90
        )
      }
    }
    youtube
    attribution
    caption
  }
`;

export const imageGridFragment = graphql`
  fragment imageGridFragment on CMS_ImageGridBlock {
    blockType
    imageBlocks {
      id
      attribution
      caption
      image {
        ...WagtailImageSharpSource
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 90
          )
        }
        height
        width
        focalPointX
        focalPointY
      }
    }
  }
`;
